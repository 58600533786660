import axios from "axios";
import {creds} from "@/states/Cred";

export default class AccountService {

    getAccounts() {
        return axios.get(`${process.env.VUE_APP_API_URL}/accounts`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res.data)
            .catch(err => console.error(err));
    }

    createAccount(createAccount) {
        return axios.post(`${process.env.VUE_APP_API_URL}/accounts`,
            createAccount,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response)
    }
}
