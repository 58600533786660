import {lastSearchCriteria, playstoreReviewList, reviewListState} from '@/states/State';
import axios from "axios";
import {creds} from "@/states/Cred";

export default class PlaystoreReviewService {

    async getReviews(index, maxResults) {
        console.log(index + " " + maxResults)
        let moreReviews = true
        let token = null
        playstoreReviewList.list = { reviews: [] }
        while (moreReviews) {
            const url = `${process.env.VUE_APP_API_URL}/${lastSearchCriteria.account.accountName}/${lastSearchCriteria.app.packageName}/reviews`
            let response = await axios.get(url, {
                headers: {"Authorization": `Bearer ${creds.token}`},
                params: {token: token}
            })
            let data = response.data
            const list = (new ReviewTransformer()).transform(data);
            playstoreReviewList.list.reviews.push(...list.reviews);
            playstoreReviewList.hasReviews = reviewListState.rawData;
            if (data.tokenPagination == null) {
                moreReviews = false;
                break;
            }

            if (!data.tokenPagination.nextPageToken == null) {
                moreReviews = false;
                break;
            }

            token = data.tokenPagination.nextPageToken
        }
    }

    async sendComment(reviewId, comment) {
        const url = `${process.env.VUE_APP_API_URL}/${lastSearchCriteria.account.accountName}/${lastSearchCriteria.app.packageName}/reviews/${reviewId}`
        const response = await axios.post(url, {'replyText': comment}, {headers: {"Authorization": `Bearer ${creds.token}`}});
        return response.data;
    }

}

class ReviewTransformer {
    transform(reviews) {
        reviews.reviews.forEach(review => {
            review.comments[0].userComment.text = review.comments[0].userComment.text.slice(1)
            review.reviewerLang = review.comments[0].userComment.reviewerLanguage;
            review.rating = review.comments[0].userComment.starRating;
            var date = new Date(0);
            date.setUTCSeconds(review.comments[0].userComment.lastModified.seconds);
            review.date = date;
            review.answered = !!review.comments.find((comment) => {
                return comment.developerComment
            });
            // review.answered = !!review.comments[0].developerComment;
        });
        return reviews;
    }
}
