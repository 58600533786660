<template>
	<div class="layout-footer">
<!--		<img alt="Logo" :src="footerImage()" height="20" class="mr-2" />-->
    <span style="font-size: 130%; padding-left:10px;">🦹🏻</span>
    <span style="font-size: 130%;"><sup>💬</sup></span>
    <span>&nbsp; by Mat Ching Ham</span>
<!--		<span class="font-medium ml-2">PrimeVue</span>-->
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>

<style scoped>

</style>
