import {appState} from '@/states/State';
import axios from "axios";
import {creds} from "@/states/Cred";

export default class PlaystoreAppService {

    getApps(accountId) {
        return axios.get(`${process.env.VUE_APP_API_URL}/apps/account/${accountId}`, {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response);
    }

    createApp(app) {
        return axios.post(`${process.env.VUE_APP_API_URL}/apps`,
            app,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response);
    }

    deleteApp(accountId, appId) {
        return axios.delete(`${process.env.VUE_APP_API_URL}/apps/account/${accountId}/${appId}`,
            {headers: {"Authorization": `Bearer ${creds.token}`}})
            .then(res => res)
            .catch(err => err.response);
    }


    getAppIndex(appPackageName) {
        const app = appState.playstoreApps.find((app) => {
            return app.packageName === appPackageName
        });
        return app.listIndex;
    }

    setAppIndex(appPackageName, index) {
        const app = appState.playstoreApps.find((app) => {
            return app.packageName === appPackageName
        });
        app.listIndex = index;
    }
}
