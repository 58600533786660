import {createRouter, createWebHashHistory} from 'vue-router';
import {creds} from '@/states/Cred';

const routes = [
    {
        path: '/createAccount',
        name: 'createAccount',
        component: () => import('./components/CreateAccount.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/createApp',
        name: 'createApp',
        component: () => import('./components/CreateApp.vue'),
        meta: {
            requiresAuth: true,
        }
    }, {
        path: '/deleteApp',
        name: 'deleteApp',
        component: () => import('./components/DeleteApp.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/templates',
        name: 'templates',
        component: () => import('./components/Templates.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/reviewfetch',
        name: 'reviewfetch',
        component: () => import('./components/ReviewFetch.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/criteria',
        name: 'criteria',
        component: () => import('./components/Criteria.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/',
        name: 'emptyPage',
        component: () => import('./components/EmptyPage.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/playstoreReviewList',
        name: 'playstoreReviewList',
        component: () => import('./components/PlaystoreReviewList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/Login.vue'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!creds.token) {
            next({
                path: 'login',
            })
        } else {
            if (to.meta.roles && to.meta.roles.length > 0) {
                if (to.meta.roles.some(r => creds.user.roles.includes(r)))
                    next()
                else {
                    next(false);
                }
            } else {
                next();
            }
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router;
