// holds app names and the index no which the user last searched
const appState = {}

import {reactive} from 'vue';
// holds last search criteria of specified user
const lastSearchCriteria = reactive({
    account: {},
    app: {},
    filter: {
        rating: 0,
        rating2: 5,
        keywords: [],/*["hello","scam app","can't figure out"],*/
        calendarValue: null,    // not used for now
        lang: "en",
        answered: false
    }

});

const reviewListState = {
    none: 'none',
    rawData: 'rawData',
    filtered: 'filtered'
}

const playstoreReviewList = reactive({
    hasReviews: reviewListState.none,
    list: null
});

export {appState, lastSearchCriteria, playstoreReviewList, reviewListState};
