<template>
    <div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle"/>

        <div class="layout-sidebar" v-if="creds.token" @click="onSidebarClick">
            <div class="layout-menu-container">
                <ul class="layout-menu">
                    <li class="layout-menuitem-category" role="none">
                        <div class="layout-menuitem-root-text">Account</div>
                        <ul class="p-fluid">
                            <li>
                                <Dropdown @change="accountChanged()" v-model="lastSearchCriteria.account"
                                          :options="accounts"
                                          optionLabel="accountName" placeholder="Select"/>
                                <Button label="Create Account" class="p-button-link" @click="createAccount()"/>
                            </li>
                            <li>
                                <Button label="Templates" class="p-button-link" @click="templates()"/>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="layout-menu-container">
                <ul class="layout-menu">
                    <li class="layout-menuitem-category" role="none">
                        <div class="layout-menuitem-root-text">Playstore</div>
                        <ul class="p-fluid">
                            <li>
                                <Dropdown @change="playstoreAppChanged()" v-model="lastSearchCriteria.app"
                                          :options="playstoreApps"
                                          optionLabel="appName" placeholder="Select"/>
                                <Button label="Create App" class="p-button-link" @click="createApp()"/>
                            </li>
                            <li>
                                <Button label="Delete App" class="p-button-link" @click="deleteApp()"/>
                            </li>
                            <li>
                                <Button label="Auto Review" class="p-button-link" @click="criteria()"/>
                            </li>
                            <li>
                                <Button label="Review Fetch" class="p-button-link" @click="reviewFetch()"/>
                            </li>

                        </ul>
                    </li>
                </ul>
            </div>
            <AppMenu v-if="false" :model="menu" @menuitem-click="onMenuItemClick"/>
        </div>

        <div class="layout-main-container">
            <div class="layout-main">

                <router-view @acall="caller" v-on:accountChanged="refreshAccounts()" v-on:appChanged="refreshApps()"/>
            </div>
            <AppFooter/>
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
    </div>
</template>

<script>
import AppTopBar from '../AppTopbar.vue';
import AppMenu from '../AppMenu.vue';
import AppFooter from '../AppFooter.vue';
import router from "../router";
import PlaystoreAppService from "@/service/PlaystoreAppsService";
import PlaystoreReviewService from "@/service/PlaystoreReviewService";
import AccountService from "@/service/AccountService";
import {creds} from '@/states/Cred';
import {lastSearchCriteria} from "@/states/State";

export default {

    created() {
        this.accountService = new AccountService();
        this.playstoreAppService = new PlaystoreAppService();
        this.playstoreReviewService = new PlaystoreReviewService();
    },
    mounted() {
        // this.playstoreAppService.getApps().then((countries) => {
        //   this.countries = countries;
        // });
        this.accountService.getAccounts().then(accounts => this.accounts = accounts);
    },
    data() {
        return {
            creds,
            accountService: null,
            playstoreAppService: null,
            playstoreReviewService: null,
            accounts: null,
            selectedAccount: null,
            playstoreApps: [
                {name: 'Mortar Clash', code: 'NY'},
                {name: 'Braindom 1', code: 'LDN'},
                {name: 'Braindom 2', code: 'IST'},
                {name: 'Paris', code: 'PRS'}
            ],
            playstoreSelectedApp: null,
            appstoreSelectedApp: null,
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        }
    },
    watch: {
        'creds.token': function () {
            this.accountService.getAccounts().then(accounts => this.accounts = accounts);
        },

        'lastSearchCriteria.appPackageName': function (val) {
            this.index = this.playstoreAppService.getAppIndex(val);
            this.maxResults = 100;
        },

        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }

    },
    methods: {
        caller() {
        },

        createAccount() {
            router.push('/createAccount')
        },

        createApp() {
            router.push("/createApp")
        },

        deleteApp() {
            router.push("/deleteApp")
        },

        criteria() {
            router.push("/criteria")
        },

        templates() {
            router.push("/templates")
        },

        reviewFetch() {
            router.push("/reviewFetch")
        },

        refreshAccounts() {
            this.accountService.getAccounts().then(accounts => this.accounts = accounts);
        },

        refreshApps() {
            this.playstoreAppService.getApps(lastSearchCriteria.account._id)
                .then(res => {
                    if (res.status !== 200) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error Occured While Fetching Apps',
                            detail: res.status + " " + res.data.detail,
                            life: 3000
                        });
                    } else {
                        this.playstoreApps = res.data
                    }
                });
        },

        accountChanged() {
            this.refreshApps()
        },

        playstoreAppChanged() {
            router.push('/playstoreReviewList');
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if (this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        lastSearchCriteria() {
            return lastSearchCriteria
        },
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                'p-ripple-disabled': this.$primevue.config.ripple === false,
                'layout-theme-light': this.$appState.theme.startsWith('saga')
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import '../assets/styles/App.scss';
</style>
